import React, { useCallback } from "react"
import Layout from "components/member/signUp/layout.js"
import * as styles from "./single.module.css"
import Password from "components/member/signUp/password.js"
import { nameCheckUtil, join, idCheck } from "utils/memberUtils"
import Input from "components/tools/input.js"
import Title from "components/tools/InputTitle.js"
import MobileCheck from "components/tools/mobileCheck.js"
import { useContext } from "react"
import modalContext from "context/modalContext"
const SignUpSinglePage = props => {
  const nameCheck = useCallback(e => {
    nameCheckUtil(e)
  }, [])

  console.log(props?.location?.state)

  const { setmodalOpen, setModalMain } = useContext(modalContext)

  return (
    <Layout title={"회원가입"}>
      <div className={`${styles.conGap} colContainer`}>
        <Input
          title={"아이디"}
          tagname={"id"}
          inputStyle={{ fontSize: "14px", width: "200px" }}
          btn={
            <div className={styles.btn} onClick={idCheck}>
              중복확인
            </div>
          }
        />
        <div>
          <Title name="비밀번호" />
          <Password inputStyle={{ fontSize: "14px" }} />
        </div>
        <div>
          <Input
            title={"이름"}
            tagname={"name"}
            onChange={nameCheck}
            inputStyle={{ fontSize: "14px" }}
          />
        </div>
        <div>
          {/* 모바일 인증 */}
          <Title name="휴대전화" />
          <MobileCheck inputStyle={{ fontSize: "14px" }} />
        </div>
        <div>
          <Input
            title={"이메일"}
            tagname={"email"}
            inputStyle={{ fontSize: "14px" }}
            left={
              <p
                style={{
                  paddingLeft: "10px",
                  paddingTop: "5px",
                }}
              >
                피치서가 이용 안내가 이메일로 제공됩니다.
                <br /> 본인의 이메일을 정확하게 입력해주세요.
              </p>
            }
            placeholder={"이메일 주소 입력"}
          />
        </div>
        {/* 버튼 */}
        <div
          className={`${styles.btnGap} longBtn`}
          onClick={() => join(setmodalOpen, setModalMain)}
        >
          피치서가 가입하기
        </div>
      </div>
    </Layout>
  )
}

export default SignUpSinglePage
